.share-product, .share-article{
  height:50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin:10px 0;
  .share-icon{
    width: 100px;
    padding: 0 10px 0 0;
    position: relative;
    @include formatText(700, 16px, $ECHO-Orange, uppercase, pointer);
  }

  .share-icon::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 0;
    height: 3px;
    width:100%;
    background-color: $ECHO-Orange;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  .share-icon:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }


  .option-to-share{
    visibility:hidden;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .social_share i{
    font-size: 16px;
    color: $mainGray;
    &:hover{
      color: $ECHO-Orange;
    }
  }
}