.close-icon {
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  & span {
    background-color: $ECHO-Black;
    width: inherit;
    height: 2px;
    position: absolute;
  }
  span:nth-child(1){
    transform: rotate(45deg);
  }
  span:nth-child(2){
    transform: rotate(-45deg);
  }
}
