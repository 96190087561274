// Body
$body-bg: white;

// Typography
$font-family-sans-serif:  'Poppins', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;


$ECHO-Orange: #ee5730;
$ECHO-Black: #252729;
$ECHO-Gray: #808080;
$ECHO-lightGray: #ccc;


$ECHO-bg-light-gray: #e5e5e5;
$ECHO-bg-dark-gray: #cfcfcf;
$ECHO-White: rgba(255, 255, 255, 0.92);
