.footer-content {
  background-color: $ECHO-bg-dark-gray;
  width: 100%;
  padding: 40px 0;

  .footer-menu, .social-media-footer {
    width: 25%;
  }

  .footer-categories {
    display: flex;
    width: 50%;

    ul {
      width: 50%;
    }
  }

  .footer-item {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .footer-anchor {
    @include formatText(400, 14px, $ECHO-Black, none, pointer);
    text-decoration: none;
    margin: 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 1rem;
      right: 0;
      height: 3px;
      background-color: $ECHO-Orange;
      transform-origin: bottom right;
      transform: scaleX(0);
      transition: transform 0.5s ease;
    }

    &:hover::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
    border-top: 1px solid $ECHO-Gray;
    margin-top: 20px;

    .left{
      @include formatText(400, 12px, $ECHO-Black, capitalize, default);
    }

    .right {
      span {
        @include formatText(400, 12px, $ECHO-Black, none, default);
      }

      a {
        @include formatText(400, 12px, $ECHO-Black, none, pointer);
        text-decoration: none;
        &:hover{
          color:$ECHO-Orange;
        }
      }
    }

  }

  @media only screen and (max-width: 991.98px) {
    padding: 20px;
    .container {
      flex-direction: column;
    }

    .footer-menu, .social-media-footer {
      width: 100%;
      margin: 20px 0;
    }

    .footer-categories {
      padding: 20px 0;
      border-top: 1px solid $ECHO-Gray;
      border-bottom: 1px solid $ECHO-Gray;
      flex-direction: column;
      width: 100%;

      ul {
        width: 100%;
      }
    }

  }

  @media only screen and (max-width: 574.98px){
    .bottom-footer{
      height: 80px;
      margin-top: 0;
      padding-top: 20px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

}