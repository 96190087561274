.search {
  width: 80%;
  margin: 0 auto;

  .section-identification {
    .search-section-title {
      @include formatText(700, 30px, $ECHO-Black, uppercase, default);
      position: relative;
      width: fit-content;
      margin-top:40px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 1rem;
        bottom: 0;
        right: 0;
        height: 3px;
        background-color: $ECHO-Orange;
      }
    }
  }

  .section-content {
    margin:40px 0;
    display: block;

    .search-result-title {
      display: block;
      @include formatText(400, 12pt, $ECHO-Black, none, default);
      margin-bottom: 40px;
    }

    .card {
      margin-bottom: 20px;
      border: none;

      .row {
        align-items: center;
      }

      .img-fluid {
        cursor: pointer;
      }

      .card-title {
        @include formatText(800, 14pt, $ECHO-Black, capitalize, default);
        cursor: pointer;
        margin: 0;
      }

      .card-cat-text {
        @include formatText(400, 8pt, $ECHO-Black, lowercase, default);
        margin: 0;
        line-height: 7pt;
      }

      .card-text {
        @include formatText(400, 10pt, $ECHO-Black, lowercase, default);
        margin: 0;
      }
    }

    a:hover {
      text-decoration-color: $ECHO-Black;
      cursor: pointer;
    }

    .search-result-count {
      display: block;
      @include formatText(400, 10pt, $ECHO-Black, none, default);
      margin-bottom: 40px;
    }
  }

  .page-link {
    border:1px solid $ECHO-Orange;
    background-color: $ECHO-White;
    @include formatText(400, 12pt, $ECHO-Orange, uppercase, default);
    &:focus{
      box-shadow: none;
    }
  }
  .page-link.active {
    background-color: $ECHO-Orange;
    @include formatText(700, 12pt, $ECHO-White, uppercase, default);
  }

  @media only screen and (max-width: 767.98px) {
    .section-content .card {
      .card-title {
        font-size: 14pt;
      }

      .card-block {
        padding-right: 0!important;
      }
    }
  }
}
