#product-category {
  @import "../components/breadcrumb";

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .left-side {
    .category-list li, .subcategory-list li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      width: 100%;
      padding-left: 20px;
    }

    .category-list {
      li {
        background-color: $ECHO-Orange;

        .category-name {
          text-decoration: none;
          @include formatText(500, 14px, $ECHO-White, uppercase, pointer);
        }

        .arrow {
          display: none;
        }
      }
    }

    .subcategory-list {
      padding: 0 0 0 20px;
      border: 2px solid $ECHO_Orange;

      li {
        background-color: $ECHO-White;

        a {
          text-decoration: none;
          @include formatText(400, 14px, $ECHO-Black, uppercase, pointer);
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            left: 1rem;
            bottom: 0;
            right: 0;
            height: 3px;
            background-color: $ECHO-Orange;
            transform-origin: bottom right;
            transform: scaleX(0);
            transition: transform 0.5s ease;
          }

          &:hover::after {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  .right-side {
    .filters-nav {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $ECHO-bg-light-gray;
      padding: 0 10px;
      div.filter-options{
        float: left;
      }

      .open-filters {
        @include formatText(800, 12pt, $ECHO-Orange, uppercase, pointer);
        &:before {
          content: '>';
          padding-right: 5px;
          @include formatText(800, 12pt, $ECHO-Orange, uppercase, pointer);
        }
      }

      .clear-filters {
        opacity:0;
        padding-left:30px;
        @include formatText(500, 10pt, $ECHO-Orange, uppercase, pointer);
        &:before {
          content: 'x';
          padding-right: 5px;
          @include formatText(500, 10pt, $ECHO-Orange, uppercase, pointer);
        }
      }

      .order-icom-stock {
        width: 35px;
        margin: 0;
      }
    }

    .filter-info {
      opacity: 0;
      padding-top: 0;
      display: none;
      border: 3px solid $ECHO-bg-light-gray;

      .close-icon {
        margin: 10px 20px;
        width: 20px;
        height: 20px;
      }
    }

    .filter-content {
      display: none;
      flex-wrap: wrap;
      width: 100%;
      margin: 10px;
      column-gap: 40px;

      .form-group {
        width: 20%;
        margin-bottom: 20px;
      }

      .form-check {
        padding-left: 0;
      }

      .filter-title {
        @include formatText(800, 10pt, $ECHO-Black, uppercase, pointer);
      }

      .form-check-label {
        @include formatText(400, 10pt, $ECHO-Black, none, pointer);
      }

      .form-check-input {
        cursor: pointer;

        &:checked {
          accent-color: $ECHO-Orange;
        }
      }

      input[type=checkbox] {
        position: inherit;
        margin: 0;
        padding: 0;
        width: 20px;
      }
    }

    .order-content {
      display: flex;
      align-items: center;
      justify-content: center;
      right: 15px;
      z-index: 9;

      span {
        width: 20px;
        display: block;
        margin: 0 10px;
        cursor: pointer;
      }

      img {
        width: 100%;
      }
    }
  }

  .btn-to-load-products {
    height: 60px;
    width: 345px;
    background-color: $ECHO-Orange;
    @include formatText(500, 14pt, $ECHO-White, uppercase, pointer);
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 574.98px) {
      width: fit-content;
      padding: 10px 15px;
      font-size: 10pt;
    }

    &:hover {
      .arrow {
        opacity: 1;
        transform: translateX(5px);
      }
    }

    .arrow {
      width: 15px;
      padding-left: 10px;
      opacity: 0;
      transform: translateX(-10px);
      will-change: transform;
      transition: all 0.5s ease-in-out;
    }
  }
}

@media only screen and (min-width: 1200px) {
  #product-category {
    margin: 40px 60px;

    .right-side {
      width: calc(80% - 40px);
      margin-left: 40px;
    }

    .left-side {
      width: 20%;
    }

    .first-block-products, .second-block-products, .third-block-products {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 30px 0;

      .product-detail-card {
        display: flex;
      }
    }

    .btn-to-load-products {
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width:1499.98px) {
  #product-category {
    .first-block-products, .second-block-products, .third-block-products {
      grid-gap: 20px;
    }
  }

}

@media only screen and (min-width: 1500px) {
  #product-category {
    .first-block-products, .second-block-products, .third-block-products {
      grid-gap: 35px;
    }
  }
}



@media only screen and (max-width: 1199.98px) {
  #product-category {
    margin: 30px 50px;

    .row {
      margin: 0;
    }

    .right-side {
      width: 100%;
      padding: 40px 0 0 0;
    }

    .left-side {
      width: 100%;
    }

    .all-products{
      padding-top:40px;
    }

    .first-block-products, .second-block-products, .third-block-products {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      grid-gap: 40px;

      .product-detail-card {
        display: flex;
        .product-info-detail-block {
          padding: 10px;
        }
      }
    }

    .third-block-products {
      margin: 40px 0;
    }

    .btn-to-load-products {
      margin: 40px auto 0 auto;
    }
  }
}

@media only screen and (max-width: 574.98px) {
  #product-category {
    margin: 20px 40px;

    .right-side {
      .filters-nav {
        margin-bottom: 20px;
      }

      .filter-info {
        position: absolute;
        width: calc(100% - 80px);
        background: $ECHO-White;
        z-index: 10;
        margin-top: -20px;

        .close-icon {
          margin: 10px;
        }
      }

      .filter-content {
        flex-direction: column;
        margin: 10px;

        .form-group {
          width: 100%;
        }
      }
    }

    .left-side {
      .category-list li {
        .arrow {
          display: flex;
          width: 15px;
          margin-left: 20px;
          color: $ECHO-White;
          transform: translateX(-10px);
          will-change: transform;
          transition: all 0.5s ease-in-out;
        }
      }

      .subcategory-list {
        padding: 0;
      }

      .category-list li .category-name,
      .subcategory-list li a {
        font-size: 12px;
      }

      .hideList {
        display: none;
      }
    }

    .first-block-products, .second-block-products, .third-block-products {
      grid-gap: 20px;
    }
  }
}

@media only screen and (min-width: 2000px) {
  #product-category {
    .row{
      justify-content: center;
    }
    .right-side {
      max-width: 1720px;
    }

    .left-side {
      max-width: 280px;
    }
  }
}
