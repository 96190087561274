#newsCategory {
  .header-page {
    height: 80px;
    display: flex;
    align-items: flex-end;


    .title-page {
      @include formatText(700, 22pt, $ECHO-Black, uppercase, default);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 1rem;
        bottom: 0;
        right: 0;
        height: 3px;
        background-color: $ECHO-Orange;
      }
    }
  }

  .body-page {
    .article-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .article-date {
        @include formatText(400, 10pt, $ECHO-Black, uppercase, default);
      }

      .article-title {
        @include formatText(800, 17pt, $ECHO-Black, none, default);
        margin: 5px 0 0 0;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 1rem;
          bottom: 0;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
        }
      }

      .article-excerpt {
        @include formatText(400, 10pt, $ECHO-Black, none, default);
        margin: 10px 0;
      }

      .article-read-more {
        text-decoration: none;
        @include formatText(800, 10pt, $ECHO-Orange, uppercase, pointer);
        &:hover {
          .arrow {
            opacity: 1;
            padding-left: 10px;
            transform: translateX(-20px);
          }
        }

        .arrow {
          width: 15px;
          opacity: 0;
          transform: translateX(0);
          will-change: transform;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .no-news{
      @include formatText(400, 16px, $ECHO-Black, none, default);
    }

    @media only screen and (min-width: 992px) {
      .each-article {
        display: flex;
        flex-wrap: nowrap;
        margin: 20px 0;

        &:nth-child(odd) {
          justify-content: flex-start;

          .article-img {
            order: 1;
          }

          .article-info {
            order: 2;
          }
        }

        &:nth-child(even) {
          justify-content: flex-end;
          .article-img {
            order: 2;
          }

          .article-info {
            order: 1;
          }
        }
      }

      .article-img{
        width: 50%;
        padding: 20px 30px;
      }

      .article-info{
        background-color: $ECHO-bg-light-gray;
        width: 50%;
        margin:20px;
        padding:30px;
      }

      .img-clip {
        height: 260px;
        width: 100%;
        object-fit: cover;
      }

      .no-news-content{
        height: calc(100vh - 420px);
        padding: 40px 0;

      }
    }

    @media only screen and (max-width: 991.98px) {
      .no-news-content{
        height: 300px;
        padding: 30px 0;
      }
      .each-article {
        margin: 50px 0;
        &:nth-child(odd){
          background-color: $ECHO-bg-light-gray;
        }
      }

      .article-img, .article-info {
        width: 100%;
      }

      .article-info {
        padding: 20px;
      }

      .img-clip {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
