#homepage{
  .header {
    width: fit-content;
    margin-top: 40px;
    height: 60px;
    display: flex;
    align-items: center;
    .title {
      @include formatText(700, 22px, $ECHO-Black, uppercase, default);
      margin: 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 1rem;
        right: 0;
        height: 3px;
        background-color: $ECHO-Orange;
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }
  }

  @import "../components/hp/novelty-section";
  @import "../components/hp/product-category";
  @import "../components/hp/featured-section";
  @import "../components/hp/catalog-section";

  @media only screen and (max-width: 574.98px) {
    .container{
      padding: 0 40px;
    }
  }
}
