.technical-details-navbar {
  margin-top:30px;

  .nav-tabs {
     border-bottom: 0;
  }

  .nav-link {
    width: 50%;
    height: 40px;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    @include formatText(400, 12pt, $ECHO-Black, uppercase, pointer);
    position: relative;
    padding-left:0;

  }

  .nav-link.active {
    @include formatText(600, 12pt, $ECHO-Black, uppercase, pointer);
    border: 0;
    position:relative;
    &::before{
      content: '';
      position: absolute;
      width:85%;
      left: 1rem;
      bottom: 0;
      right: 0;
      height: 3px;
      background-color: $ECHO-Orange;
    }
  }

  .nav-link::after {
    content: '';
    position: absolute;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  .nav-link:hover::after {
    text-decoration: underline;
    content: '';
    position: absolute;
    width: 40%;
    left: 1rem;
    bottom: 0;
    right: 0;
    height: 3px;
    background-color: $ECHO-Orange;
    transform-origin: bottom left;
    transform: scaleX(1);
    transition: transform 0.5s ease;
  }
}

.no-info {
  @include formatText(400, 12pt, $ECHO-Black, uppercase, default);
}

#nav-tabContent {
  min-height: 200px;
  margin-top: 20px;
}

.product-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $ECHO-Gray;
    border-radius: 20px;
    border: 1px solid transparent;
  }

  tbody tr:nth-of-type(even) {
    background-color: $ECHO-lightGray;
  }

  tr > th, tr > td {
    min-width: 100px;
    padding: 5px;
    @include formatText(400, 14px, $ECHO-Black, capitalize, default);
    border: 2px solid white;

    &:first-child{
      text-align: left;
    }

    &:not(:first-child){
      text-align: center;
    }
  }
}

.info-product-tab {
  @include formatText(400, 12pt, $ECHO-Black, none, default);
}

.dynamic-documents-list {
  margin-top: 20px;

  label {
    @include formatText(700, 12pt, $ECHO-Black, none, default);
  }

  .dynamic-documents-list-files {
    list-style: none;
    padding: 0;

    li a {
      @include formatText(400, 12pt, $ECHO-Black, none, pointer);

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .technical-details-navbar .nav-link, .product-table tr > td,
  .technical-details-navbar .nav-link.active{
    font-size: 13px;
  }
}
