.left-side {
  background-color: $ECHO-bg-dark-gray;
  width: 60%;

  .categories-menu {

    @media only screen and (min-width: 768px) {
      padding: 20px;
    }


    .menu-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }

    .anchor-menu-item {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;

      .category-logo {
        width: 35px;
        height: 40px;
        margin-right: 10px;
      }

      .category-name {
        @include formatText(500, 16px, $ECHO-Black, none, pointer);
        margin: 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 1rem;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
          transform-origin: bottom right;
          transform: scaleX(0);
          transition: transform 0.5s ease;
        }
      }
    }

    .anchor-menu-item:hover {
      .category-name::before {
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }

    .sub-categories {
      width: 100%;
     .category-name {
       font-weight: 400;
       font-size: 14px;
      }

      &.show {
        display: initial;
      }

      &.hide {
        display: none;
      }

      .sub-categories {
        padding-inline-start: 20px;
        .category-name {
          font-weight: 400;
          font-size: 14px;
        }
      }

      .anchor-menu-item {
        padding-left: 10px;
        height: 40px;
      }
    }

  }

  .inco-childs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease-in-out;

    .arrow {
      border-top: 2px solid $ECHO-Black;
      border-right: 2px solid $ECHO-Black;
      width: 12px;
      height: 12px;
      transform: rotate(135deg);
      margin-top: -8px;
      margin-left: 15px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
}

