.categories-section {
  width: 100%;

  .body {
    width: 100%;

    .categories-list {
      display: flex;
      flex-wrap: wrap;

      .anchor-category {
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: $ECHO-bg-light-gray;

        &:hover {
          .category-name::before {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }
      }

      .category-logo {
        width: 100%;
        max-width: 40px;
        max-height: 40px;
        margin-right: 10px;
        object-fit: contain;
      }

      .no-category-logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        object-fit: contain;
      }

      .category-name {
        @include formatText(500, 14px, $ECHO-Black, none, pointer);
        margin: 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 90%;
          bottom: 0;
          left: 1rem;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
          transform-origin: bottom right;
          transform: scaleX(0);
          transition: transform 0.5s ease;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .body {
      .categories-list {
        gap: 20px;

        .anchor-category {
          width: calc(20% - 20px);
          padding: 15px 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1199.98px) and (min-width: 992px) {
    .body {
      .categories-list {
        gap: 10px;

        .anchor-category {
          width: calc(20% - 10px);
          padding: 10px 5px;
        }
      }
    }
  }

  @media only screen and (max-width: 991.98px) and (min-width: 768px) {
    .body {
      .categories-list {
        gap: 15px;

        .anchor-category {
          width: calc((100% / 3) - 10px);
          padding: 15px 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 767.98px) and (min-width: 575px) {
    .body {
      .categories-list {
        gap: 15px;

        .anchor-category {
          width: calc((100% / 2) - 10px);
          padding: 15px 10px;
        }

        .category-logo {
          max-width: 40px;
          max-height: 40px;
        }

        .no-category-logo {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  @media only screen and (max-width: 574.98px) {
    .body {
      .categories-list {
        flex-direction: column;
        gap: 10px;

        .anchor-category {
          width: 100%;
          height: 80px;
          padding: 15px 10px;
        }

        .category-logo {
          max-width: 50px;
          max-height: 50px;
          margin-right:20px;
        }

        .no-category-logo {
          width: 50px;
          height: 50px;
          margin-right:20px;
        }
      }
    }
  }
}
