#article-page{
  margin:0 0 40px 0;
  .bg-img{
    width: 100%;
    height:300px;
    object-fit: cover;
  }
  .title {
    @include formatText(700, 22pt, $ECHO-Black, uppercase, default);
    position: relative;
    width: fit-content;
    margin-top:40px;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 1rem;
      bottom: 0;
      right: 0;
      height: 3px;
      background-color: $ECHO-Orange;
    }
  }

  .excerpt{
    margin:30px 0;
    @include formatText(400, 16px, $ECHO-Black, none, default);
  }

  .content > *{
    @include formatText(400, 14px, $ECHO-Black, none, default);

  }
  .content ul{
    list-style-type: disc;
    padding-inline-start: 40px;
    margin-bottom: 1rem;
  }
}
