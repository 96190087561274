.loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
}

.balls {
  width: 5.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.balls div {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #ee5730;
}

.balls div:nth-of-type(1) {
  transform: translateX(-100%);
  animation: left-swing 0.6s ease-in alternate infinite;
}

.balls div:nth-of-type(3) {
  transform: translateX(-90%);
  animation: right-swing 0.6s ease-out alternate infinite;
}

@keyframes left-swing {
  50%,
  100% {
    transform: translateX(90%);
  }
}

@keyframes right-swing {
  50% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(100%);
  }
}