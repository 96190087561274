#extra {
  height: 60px;
  width: 100%;
  position: relative;
}

#horizontal-navbar {
  background-color: $ECHO-Orange;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;

  .first-block, .search-in-desktop {
    display: flex;
    align-items: center;
  }

  .icon-menu-block {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-menu {
    width: 34px;
    padding: 4px;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      transition: all .3s;
    }
  }

  .closed-menu {
    span + span {
      margin-top: 6px;
    }
  }

  .open-menu {
    span + span {
      margin-top: -2px;
    }
  }

  .open-menu span:nth-child(1) {
    animation: ease .7s top forwards;
  }

  .closed-menu span:nth-child(1) {
    animation: ease .7s top-2 forwards;
  }

  .open-menu span:nth-child(2) {
    animation: ease .7s scaled forwards;
  }

  .closed-menu span:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
  }

  .open-menu span:nth-child(3) {
    animation: ease .7s bottom forwards;
  }

  .closed-menu span:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
  }

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 22px;
      transform: rotate(0);
    }
    100% {
      top: 22px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 22px;
      transform: rotate(45deg);
    }
    50% {
      top: 22px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 22px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 22px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .logo-anchor {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    background-color: white;
  }


  @media only screen and (min-width: 575px) {
    .search-in-desktop {
      display: flex
    }

    .search-in-mobile,
    .search-to-input,
    #close-search-box,
    #open-search-box {
      display: none;
    }

  }

  @media only screen and (max-width: 574.98px) {
    .search-in-desktop{
      display: none
    }

    .search-in-mobile {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .search-box {
        display: none;
      }
    }

    #open-search-box {
      display: flex;
      justify-content: center;
      width: 100%;
      cursor:pointer;
      i{
        color:white;
      }
    }

    #close-search-box {
      display: none;
      .close-icon span {
        background-color: $ECHO-White;
      }
    }

    .search-to-input {
      display: flex;
      width: 100%;
      top: 60px;
      height: 60px;
      background: $ECHO-Orange;
      opacity: 0.7;
      position: absolute;
    }
  }
}