#product-page {
  @import "../components/breadcrumb";

  .fotorama .fotorama__thumb-border {
    border-color: $ECHO-Orange;
  }

  @media only screen and (min-width: 1200px) {
    .in-desktop {
      display: flex;
    }

    .left-block, .right-block {
      width: 50%;
      padding: 0 25px;
    }

    .full-block {
      margin: 20px 0 40px 0;
    }

    .content-category {
      height: auto;
      overflow-y: auto;
    }
  }

  @media only screen and (max-width: 1199.98px) {
    .in-desktop {
      display: flex;
      margin: 20px auto;
      flex-direction: column-reverse;
    }

    .full-block {
      margin: 40px 0 60px 0;
    }
  }

  .prod-category {
    @include formatText(500, 14px, $ECHO-Black, capitalize, default);
    margin-bottom: 10px;
  }

  .prod-name, .section-title {
    @include formatText(700, 22px, $ECHO-Black, capitalize, default);
    width: fit-content;
    position: relative;
    padding-bottom: 2px;
    margin-bottom: 2rem;
  }

  .prod-name::after, .section-title::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 1rem;
    bottom: 0;
    right: 0;
    height: 3px;
    background-color: $ECHO-Orange;
  }

  .prod-price {
    @include formatText(700, 16px, $ECHO-Orange, capitalize, default);
    margin-bottom: 10px;
  }

  .click-to-txt {
    @include formatText(700, 16px, $ECHO-Orange, capitalize, pointer);
    margin-top: 20px;
  }

  @import "./resources/sass/frontend/components/product/share-buttons";

  .related-products {
    margin-bottom: 40px;
  }

}