nav[aria-label="breadcrumb"]{
  margin: 10px 0;
  .breadcrumb{
    background-color: white;
  }
  .breadcrumb-item + .breadcrumb-item::before{
    color: $ECHO-Orange;
    font-weight: 600;
  }
  .breadcrumb-item, .breadcrumb-item a {
    @include formatText(400, 14px, $ECHO-Black, none, pointer);
  }
  .breadcrumb-item.active {
    @include formatText(500, 14px, $ECHO-Orange, none, pointer);
  }
}

@media only screen and (max-width: 574.98px) {
  nav[aria-label="breadcrumb"]{
    display:none;
  }
}