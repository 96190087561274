.news-section {
  width: 100%;

  .body {
    width: 75%;
    margin: 0 auto 0 0;

    #noveltyProducts {

      // Styling the default Slick dots
      .slick-dots {
        display: flex;
        justify-content: flex-start;
        margin:20px 0 0 0;
        list-style-type: none;

        li {
          margin: 0 0.25rem;
        }

        button {
          display: block;
          width: 1rem;
          height: 1rem;
          padding: 0;

          border: none;
          border-radius: 100%;
          background-color: rgb(238 87 48 / 55%);

          text-indent: -9999px;
        }

        li.slick-active button {
          background-color: rgb(238 87 48 / 100%);
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .news-section {
    width: 100%;
    .body {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 574.98px) {
  .news-section .body #noveltyProducts {
    .slick-dots{
      justify-content: center;
    }

    .product-detail-card {
      max-width: inherit;
      .product-anchor {
        margin: auto;
      }
    }
  }
}