.horizontal-product-card {
  flex-grow: 1;
  width: 320px;
  max-width: calc(100% / 3);
  padding: 0 20px;

  .product-anchor {
    display: flex;
    flex-grow: 1;
    text-decoration: none;
  }

  .product-anchor:hover {
    .product-title::after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }

  .product-info-block {
    padding-left: 20px;

    .product-category {
      @include formatText(500, 12px, $ECHO-Black, none, default);
    }

    .product-title {
      position: relative;
      @include formatText(700, 18px, $ECHO-Black, none, pointer);
      word-break: break-word;
      &::after {
        content: '';
        position: absolute;
        width: 40%;
        left: 1rem;
        bottom: 0;
        right: 0;
        height: 3px;
        background-color: $ECHO-Orange;
        transform-origin: bottom right;
        transform: scaleX(0);
        transition: transform 0.5s ease;
      }
    }

    .product-excerpt {
      @include formatText(400, 12px, $ECHO-Black, none, default);
      margin-bottom: 10px;
    }

    .product-price {
      @include formatText(700, 16px, $ECHO-Orange, none, default);
      margin-bottom: 15px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .product-info-block{

      .product-category {
        height: 15px;
      }

      .product-title {
        height: 22px;
      }

      .product-excerpt {
        height: 45px;
      }

      .product-price {
        height: 20px;
      }
    }
  }

}