.product-detail-card {
  .product-anchor {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    text-decoration: none;
  }

  .product-anchor:hover {
    .product-title::after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }

  .img-content {
    border: 3px solid $ECHO-bg-light-gray;
    background-color: $ECHO-White;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-info-detail-block {
    padding: 0 10px;
    background: $ECHO-bg-light-gray;
    width: 100%;

    .product-category {
      @include formatText(500, 12px, $ECHO-Black, none, default);
      margin: 15px 0 10px 0;
      height: 15px;
    }

    .product-title {
      position: relative;
      @include formatText(700, 18px, $ECHO-Black, none, pointer);
      margin-bottom: 10px;
      word-break: break-word;
      height: 22px;

      &::after {
        content: '';
        position: absolute;
        width: 90%;
        left: 1rem;
        bottom: 0;
        right: 0;
        height: 3px;
        background-color: $ECHO-Orange;
        transform-origin: bottom right;
        transform: scaleX(0);
        transition: transform 0.5s ease;
      }
    }

    .product-excerpt {
      @include formatText(400, 12px, $ECHO-Black, none, default);
      margin-bottom: 10px;
      height: 55px;
    }

    .product-price {
      @include formatText(700, 16px, $ECHO-Orange, none, default);
      margin-bottom: 15px;
      height: 20px;
    }
  }

  @media only screen and (max-width: 1199.98px) and (min-width: 992px) {
    .product-anchor {
      width: 220px;
    }
  }
}

