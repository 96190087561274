.pub {
  //  margin: 40px auto;
}

.img-pub {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 420px;
  position: relative;
}

.filter-pub {
  width: 100%;
  height: inherit;
  mix-blend-mode: multiply;
  opacity: 0.6;
  display: flex;
  align-items: center;
  position: absolute;
}

.info-pub {
  width: 240px;
  height: 420px;
  margin: auto 40px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .type-pub {
    @include formatText(700, 14pt, $ECHO-White, uppercase, default);
  }

  .title-pub {
    margin: 0;
    @include formatText(700, 24pt, $ECHO-White, uppercase, default);
  }

  .description-pub {
    @include formatText(400, 10pt, $ECHO-White, capitalize, default);
    margin-top: 35px;
    margin-bottom: 115px;
  }

  .anchor-to-pub {
    span {
      @include formatText(700, 14pt, $ECHO-White, uppercase, pointer);
      &:after {
        content: '>';
        padding-left: 5px;
        opacity: 0;
        transform: translateX(-10px);
        will-change: transform;
        transition: all 0.5s ease-in-out;
      }
    }

    &:hover {
      text-decoration: none;
      span {
        color: $ECHO-Orange;
        &:after {
          content: '>';
          padding-left: 5px;
          opacity: 1;
          transform: translateX(5px);
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .pub {
    padding: 0;
  }

  .filter-pub {
    clip-path: polygon(0% 0%, 93% 0%, 67% 100%, 0% 100%);
  }
}

@media only screen and (max-width: 991.98px) and (min-width: 768px) {
  .pub {
    padding: 0;
  }

  .filter-pub {
    clip-path: polygon(0% 0%, 45% 0%, 33% 100%, 0% 100%);
  }
}

@media only screen and (min-width: 992px) {
  .filter-pub {
    clip-path: polygon(0% 0%, 35% 0%, 25% 100%, 0% 100%);
  }

}

@media only screen and (max-width: 1199.98px) {
  .pub {
    margin: 40px auto;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .pub {
    margin: 40px 0;
    width: 100%;
    max-width: 2680px;
  }
}