.homepage-banner {
  width: 100%;
  height: 720px;
  margin: 0;
  padding: 0;
  z-index: 20;

  // Styling the default Slick dots
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    //padding: 1rem 0;
    list-style-type: none;
    position: absolute;
    bottom: 40px;
    left: 40px;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 1rem;
      height: 1rem;
      padding: 0;

      border: none;
      border-radius: 100%;
      background-color: rgb(238 87 48 / 55%);

      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: rgb(238 87 48 / 100%);
      opacity: 1;
    }

  }

  .outer-content {
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    height: 720px;
    width: 100%;
    .img-slick, .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .inner-content {
    position: absolute;
    top: 0;
    z-index: 4;
    max-width: 1920px;
    width: 700px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;

    .title {
      @include formatText(800, 40px, inherit, none, default);
      margin: 5% 0 0 0;
      text-shadow: 0 0 $ECHO-Black;
      width: 60%;
    }

    .subtitle {
      @include formatText(500, 24px, inherit, none, default);
      margin: 20px 0 40px 0;
      text-shadow: 0 0 $ECHO-Black;
      width: 60%;
    }

    .anchor {
      background-color: $ECHO-Orange;
      width: 160px;
      height: 50px;
      text-align: center;
      @include formatText(500, 14px, $ECHO-White, uppercase, pointer);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        .arrow {
          opacity: 1;
          transform: translateX(5px);
        }
      }

      .arrow {
        width: 15px;
        padding-left: 10px;
        opacity: 0;
        transform: translateX(-10px);
        will-change: transform;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .homepage-banner {
    .inner-content {
      padding: 20px;
      width: 300px;

      .title {
        font-size: 30px;
        width: 100%;
      }

      .subtitle {
        font-size: 18px;
        width: 100%;
      }
    }
  }

  .outer-content {
    img, video {
      object-fit: cover;
    }
  }
}
