@mixin formatText($font_type, $font_size, $font_color, $text_transform, $cursor){
  font-family: 'Poppins', sans-serif;
  font-weight: $font_type;
  font-size: $font_size;
  color: $font_color;
  text-transform: $text_transform;
  cursor: $cursor;
}

ul{
  padding: 0;
  list-style: none;
  padding-inline: 0;
  margin-bottom: 0!important;
}

@keyframes plain-fly {
   0% {
     transform: translate(0, 0);
     opacity: 1;
   }

   100% {
     transform: translate(100px, -115px);
     opacity: 0;
   }
 }

@keyframes plain-fall {
  0% {
    transform: rotate(0deg) translate(0, 0);
    opacity: 1;
  }

  50% {
    transform: rotate(180deg) translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: rotate(180deg) translate(50px, -75px);
    opacity: 0;
  }
}


@media only screen and (min-width: 992px) {
  main {
    min-height: calc(100vh - 340px);
  }
}

@media only screen and (max-width: 991.98px) {
  main {
    min-height: 240px;
  }
}