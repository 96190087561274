.catalog-section {
  width: 100%;
  margin: 40px 0;
  position: relative;

  .hp-bg-img {
    width: 100%;
    object-fit: cover;
    z-index: 10;
    position: relative;
  }

  .inner-content {
    z-index: 11;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(238, 72, 27, 0.2);
    display: flex;

    .first-block, .second-block {
      display: flex;
      flex-direction: column;
    }

    .first-block {
      height: inherit;

      .title-section {
        width: 40%;
        @include formatText(700, 24px, $ECHO-White, uppercase, default);
        margin-bottom: 20px;
      }

      .download-catalog {
        background-color: $ECHO_Orange;
        width: 160px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        @include formatText(500, 14px, $ECHO-White, uppercase, pointer);

        &:hover {
          .arrow {
            opacity: 1;
            transform: translateX(5px);
          }
        }

        .arrow {
          width: 15px;
          padding-left: 10px;
          opacity: 0;
          transform: translateX(-10px);
          will-change: transform;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .second-block {
      height: inherit;
      justify-content: space-between;

      .title-section {
        @include formatText(700, 24px, $ECHO-White, uppercase, default);
        margin-bottom: 20px;
      }

      #newsletter-input, #remove-newsletter-input {
        @include formatText(400, 12px, $ECHO-Black, inherit, text);
        border: none;
        width: 200px;
        height: 30px;
        padding: 0 10px;

        &:focus {
          outline: 0;
          box-shadow: 0 3px $ECHO-Orange;
        }
      }

      .submit-email {
        background-color: $ECHO-Orange;
        @include formatText(400, 12px, $ECHO-White, uppercase, pointer);
        border: none;
        width: 40px;
        height: 30px;
        padding: 5px 10px;

      }

      #subscribe-newsletter:hover {
        i {
          animation: plain-fly 1.2s ease-in-out alternate;
        }
      }

      #unsubscribe-newsletter:hover {
        i {
          animation: plain-fall 1.2s ease-in-out alternate;
        }
      }
    }

    .form-check {
      padding: 0;
      display: flex;
      align-items: center;

      #newsletter-rgpd {
        cursor: pointer;
        border-radius: 0;
        border-color: $ECHO_Gray;

        &:checked {
          accent-color: $ECHO-Orange;
        }
      }

      .rgpd-label {
        text-decoration: none;
        @include formatText(500, 12px, $ECHO-White, uppercase, pointer);
        padding-left: 5px;

        &:hover {
          color: $ECHO-Orange;
        }
      }
    }

    .newsletter-loading-form {
      display: none;
      width: 300px;
      height: 100px;
      background-color: rgba(255, 255, 255, 0.6);
    }

    .remove-from-newsletter, .add-newsletter {
      background: $ECHO-Orange;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      @include formatText(400, 12px, $ECHO-White, uppercase, pointer);
      height: 30px;
      width: 200px;
      padding: 10px 0;
    }

    .unsubscribe-newsletter, .add-newsletter {
      display: none;
    }

    .response-form {
      margin-top: 10px;
      height: 80px;
    }
  }
}


@media only screen and (min-width: 992px) {
  .hp-bg-img {
    height: 350px;
  }

  .inner-content {
    padding: 30px 60px;
    justify-content: space-between;
    align-items: flex-start;

    .first-block {
      width: 70%;
    }

    .second-block {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .catalog-section {
    .hp-bg-img {
      height: 550px;
    }

    .inner-content {
      padding: 40px;
      flex-direction: column;

      .first-block, .second-block {
        width: 100%;
      }

      .first-block {
        margin-bottom: 40px;

        .title-section {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}


@media only screen and (max-width: 574.98px) {
  .catalog-section .inner-content {
    .first-block {
      .title-section {
        width: 100%;
        font-size: 18px;
      }

      .download-catalog {
        width: 140px;
        font-size: 12px;
      }
    }

    .second-block {
      .title-section {
        font-size: 18px;
      }

      #newsletter-input, #remove-newsletter-input {
        width: 165px;
      }
    }
  }
}


