

.relatedProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
 // height: 300px;

  .slick-arrow {
    font-size: 18px;
    cursor: pointer;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    position: absolute;
    z-index: 10;
  }

  .slick-prev:first-child {
    background: rgb(255, 255, 255);
    background: linear-gradient(to right, white 0%, rgba(250, 250, 250, 0.3) 70%, rgba(250, 250, 250, 0.1) 100%);
    left: 0;
  }

  .slick-prev:last-child {
    background: rgb(255, 255, 255);
    background: linear-gradient(to left, white 0%, rgba(250, 250, 250, 0.3) 70%, rgba(250, 250, 250, 0.1) 100%);
    right: 0;
  }

  .slick-list {
    width: 95%;
    position: relative;
  }

  .product-detail-card {
    padding: 20px;
  }
}

.relatedProductsWithoutSlide {
  display: flex;
  margin-bottom: 80px;
  justify-content: flex-start;

}

@media only screen and (max-width: 991.98px) {
  .relatedProduct {
    .slick-list {
      margin: 0 auto;
      width: 95%;
    }

    .slick-slide {
      margin: 0 10px;
    }
  }
}
