#contacts-article-page {
  margin: 0 0 40px 0;

  .anchor-to-map {
    width: 100%;
    height: 350px;
    position: relative;
  }


  .bg-img {
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
  }

  .open-map {
    @include formatText(700, 20px, $ECHO-White, uppercase, pointer);
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 62px);
  }

  .body-section {
    margin: 40px auto 0 auto;
  }

  .contact-name {
    @include formatText(700, 34px, $ECHO-Black, uppercase, default);
    position: relative;
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 1rem;
      bottom: 0;
      right: 0;
      height: 3px;
      background-color: $ECHO-Orange;
    }
  }

  .contact-address {
    @include formatText(400, 16px, $ECHO-Black, none, default);
    margin: 15px 0;
  }

  .contact-number, .contact-email {
    @include formatText(400, 16px, $ECHO-Black, none, pointer);
    text-decoration: none;
    margin-bottom: 15px;

    &:hover {
      color: $ECHO-Orange;
    }
  }

  .contact-form {
    height: 100%;
    max-width: 340px;
    display: none;
  }

  .form-alert {
    margin-top: 30px;
  }

  #form_contact {
    .form-group {
      label:not(.error) {
        @include formatText(400, 12px, $ECHO-Black, none, default);
      }

      .error {
        @include formatText(400, 10px, $red, none, uppercase);
      }

      input {
        height: 40px;
        max-width: 340px;
        border-radius: 0;
        border-color: $ECHO_Gray;

        &:focus {
          outline: 0;
          box-shadow: 0 3px $ECHO-Orange;
        }
      }

      textarea {
        height: 100px;
        max-width: 340px;
        border-radius: 0;
        border-color: $ECHO_Gray;

        &:focus {
          outline: 0;
          box-shadow: 0 3px $ECHO-Orange;
        }
      }
    }

    .form-check {
      cursor: pointer;
      border-radius: 0;
      border-color: $ECHO_Gray;
      max-width: 340px;

      input[type=checkbox]:checked {
        accent-color: $ECHO-Orange;
      }

      .form-check-label:not(.error) {
        @include formatText(400, 12px, $ECHO-Black, none, pointer);
        text-align: justify;
      }

      .error {
        @include formatText(400, 10px, $red, none, uppercase);
      }

      .page-terms {
        @include formatText(400, 12px, $ECHO-Orange, none, pointer);
      }
    }

    .action-to-use {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 340px;

    }

    .btn-clear-form {
      width: 160px;
      height: 50px;
      background-color: $ECHO_White;
      border-radius: 0;
      border: 1px solid $ECHO-Gray;
      margin-top: 40px;
      @include formatText(500, 14px, $ECHO-Gray, uppercase, pointer);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $ECHO_Gray;
        @include formatText(500, 14px, $ECHO-White, uppercase, pointer);
      }
    }

    .contact-submit-button {
      width: 160px;
      height: 50px;
      background-color: $ECHO_Orange;
      border-radius: 0;
      border: 1px solid transparent;
      margin-top: 40px;
      @include formatText(500, 14px, $ECHO-White, uppercase, pointer);

      .txt {
        padding-right: 10px;
      }

      &:hover {
        i {
          animation: plain-fly 0.9s ease-in-out alternate;
        }
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
  }

  .social-media {
    margin-top: 40px;

    .social-list .social-item .social-anchor {
      @include formatText(400, 16px, $ECHO-Black, none, pointer);
      text-decoration: none;

      i {
        padding-right: 10px;
      }

      &:hover {
        color: $ECHO-Orange;
      }
    }
  }

  .info-address {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 767.98px) {
    .left-side {
      order: 2;
    }

    .right-side {
      order: 1;
    }
  }
}
