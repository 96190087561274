#warrantyCategory {
  .header-page {
    margin: 30px 0;

    .title-page {
      @include formatText(700, 22pt, $ECHO-Black, uppercase, default);
      position: relative;
      width: fit-content;
      margin-bottom: 30px;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 1rem;
        bottom: 0;
        right: 0;
        height: 3px;
        background-color: $ECHO-Orange;
      }
    }

    .subtitle-page {
      @include formatText(400, 16px, $ECHO-Black, none, default);
    }
  }

  .body-page {
    .article-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .article-title {
        @include formatText(800, 17pt, $ECHO-Black, none, default);
        margin: 5px 0 0 0;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 1rem;
          bottom: 0;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
        }
      }

      .article-excerpt {
        @include formatText(400, 10pt, $ECHO-Black, none, default);
        margin: 10px 0;
      }
    }

    .no-warranty {
      @include formatText(400, 16px, $ECHO-Black, none, default);
    }

    @media only screen and (min-width: 992px) {
      .each-article {
        display: flex;
        width: 80%;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        padding: 15px 30px;
        background-color: $ECHO-bg-light-gray;

        &:nth-child(even) {

          margin-left: auto;
          margin-right: 0;
          position: relative;

          &::before {
            background-color: $ECHO-White;
            position: absolute;
            width: 150px;
            content: '';
            height: 100%;
            left: 0;
            z-index: 0;
          }

          .img-clip {
            order: 1;
            z-index: 1;
          }

          .article-info {
            order: 2;
            padding-left: 30px;
          }
        }

        &:nth-child(odd) {
          margin-left: 0;
          margin-right: auto;
          position: relative;

          &::after {
            background-color: $ECHO-White;
            position: absolute;
            width: 140px;
            content: '';
            height: 100%;
            right: 0;
            z-index: 0;
          }

          .img-clip {
            order: 2;
            z-index: 1;
          }

          .article-info {
            order: 1;
            padding-right: 30px;
          }
        }
      }
    }

    @media only screen and (max-width: 991.98px) {
      .no-warranty-content {
        height: 300px;
        padding: 30px 0;
      }
      .each-article {
        margin: 50px 0;

        &:nth-child(odd) {
          background-color: $ECHO-bg-light-gray;
        }
      }

      .article-info {
        width: 100%;
        padding: 20px;
      }

      .img-clip {
        height: 250px;
        object-fit: cover;
        padding: 20px;
        display: flex;
        margin:auto;
      }
    }
  }
}
