.categories-nav {
  width: 50.5%;
  z-index: 90;
  position: fixed;
  height: calc(100vh - 60px);
  overflow: auto;


  &.menu-show {
    display: block;
  }

  &.menu-hide {
    display: none;
  }

  .content-inner {
    display: flex;
    height: max-content;
    min-height: 100%;
  }

  @import 'category-menu.scss';

  .right-side {
    background-color: #e5e5e5;
    width: 40%;
    padding: 30px 40px;
    border-right: 1px solid $ECHO-bg-dark-gray;

    .menu-item-anchor {
      height: 40px;
      display: flex;
      align-items: center;
    }

    .anchor-menu-item {
      text-decoration: none;

      .title-menu-item {
        @include formatText(500, 14px, $ECHO-Black, none, pointer);
        margin: 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 1rem;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
          transform-origin: bottom right;
          transform: scaleX(0);
          transition: transform 0.5s ease;
        }

        &:hover::before {
          transform-origin: bottom left;
          transform: scaleX(1);
        }
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .categories-nav {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .categories-nav {
    /*max-height: 100%;
    overflow-y: auto;*/
    .content-inner {
      .only-mob {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .categories-nav {
    background-color: $ECHO-bg-light-gray;
    .content-inner {
      flex-direction: column;
      padding: 30px 40px;
      
      .left-side {
        display: none;
        width: 100%;
        background-color: transparent;
      }

      .right-side {
        background-color: transparent;
        border-right: 0;
        padding: 0;
        width: 100%;
      }

      .only-mob {
        display: flex;
        align-items: center;
        height: 40px;
        @include formatText(500, 14px, $ECHO-Black, none, pointer);
      }
    }
  }
}