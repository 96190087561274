.list-of-additional-products {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @import "horizontal-product-card";

  #additional-multiple-products {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .horizontal-product-card {
      max-width: 100%;
    }

    .slick-arrow {
      cursor: pointer;
    }
  }
}

