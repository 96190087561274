#news-article {
  .header-page {
    height: 80px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    .title-page {
      text-decoration: none;

      h1 {
        @include formatText(700, 22pt, $ECHO-Black, uppercase, pointer);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 1rem;
          bottom: 0;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
        }
      }
    }
  }

  .body-page {
    display: flex;
    min-height: 600px;

    .article-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .article-date {
        @include formatText(400, 14px, $ECHO-Black, uppercase, default);
      }

      .article-title {
        @include formatText(700, 22px, $ECHO-Black, none, default);
        margin: 20px 0;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 1rem;
          bottom: 0;
          right: 0;
          height: 3px;
          background-color: $ECHO-Orange;
        }
      }

      .article-description {
        @include formatText(400, 14px, $ECHO-Black, none, default);
        margin: 10px 0;
      }

      @import "./resources/sass/frontend/components/product/share-buttons";
    }

    .article-img {
      .fotorama .fotorama__thumb-border {
        border-color: $ECHO-Orange;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .body-page {
      .article-info, .article-img {
        width: 50%;
      }
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .body-page .article-info {
      padding-left: 60px;
    }
  }

  @media only screen and (max-width: 991.98px) {
    .body-page {
      flex-direction: column;
      .article-info {
        width: 100%;
        order: 1;

        .article-date {
          order: 2;
        }

        .article-title {
          order: 1;
        }

        .article-description {
          order: 3;
        }

        .share-article {
          order: 4;
        }
      }

      .article-img {
        width: 100%;
        margin: 20px 0;
        order: 2;
      }
    }
  }
}
